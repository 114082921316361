import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPodcast,
    faNewspaper,
    faMicrophoneAlt,
    faQuoteRight,
    faStopwatch,
    faBookOpen,
    faImage
} from "@fortawesome/free-solid-svg-icons"

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FeaturedMarker from "./featured-marker"
import ProjectIcon from "./project-icon"
import {
    mentionsContainer,
    projects,
    project,
    timestamps,
    date,
    content,
    icon,
    iconOrLogo,
    articleContent,
    articleInfo,
    publisher,
    imageButton,
    titleAndProject,
    title,
    note
} from "./mention-list.module.scss"

function getMentionLinkTitle(node) {
    if (node.title) {
        return node.title
    }
    return "View"
}

const icons = {
    "newspaper": faNewspaper,
    "radio": faMicrophoneAlt,
    "podcast": faPodcast,
    "magazine": faBookOpen
}

function getIcon(node) {
    if (node.type && icons[node.type]) {
        return icons[node.type]
    }
    return faQuoteRight
}

function showRelatedProjects(node) {
    if (node.relatedProjects) {
        return (
            <div className={projects}>
                {node.relatedProjects.map(p => <span key={p.id} className={project}>
                    <ProjectIcon key={p.id}
                                 project={p}/></span>)}
            </div>
        )
    }
}


function showRelatedTimestamps(node) {
    if (node.timestamps) {
        return (
            <div className={timestamps}>
                <strong>

                    <FontAwesomeIcon icon={faStopwatch}/>{" "}
                </strong>
                <span>
                    {node.timestamps.join(", ")}
                </span>
            </div>
        )
    }
}

const Mention = ({ node, displayProjects, hideFeatured }) => {

    const pressLogo = node.logoImage
    const pressLogoImage = getImage(pressLogo)

    return (

        <li key={node.id} className={content + (node.featured && !hideFeatured ? " featured-item" : "")}>

            <div className={iconOrLogo}>
                {pressLogoImage && <GatsbyImage image={pressLogoImage}
                                                alt={node.publisher + " Logo"}
                                                placeholder="blurred"/>}
                {
                    !pressLogoImage &&
                    <div className={icon}>
                        <FontAwesomeIcon icon={getIcon(node)} className={"fa-2x fa-fw"}/>
                    </div>
                }
            </div>

            <div className={articleContent}>
                <div className={articleInfo}>
                    <div className={publisher}>
                        {!hideFeatured && <FeaturedMarker featured={node.featured}/>}
                        {/*{*/}
                        {/*    pressLogoImage &&*/}
                        {/*    <span className={publisherTypeIcon}>*/}
                        {/*        <FontAwesomeIcon icon={getIcon(node)} className={"fa-fw"}/>*/}
                        {/*    </span>*/}
                        {/*}*/}
                        {node.publisher}{node.publisherExtra && (": " + node.publisherExtra)}
                        {node.image &&
                            <a href={node.image.publicURL}
                               target="_blank"
                               rel="noopener noreferrer"
                               className={imageButton}>
                                <FontAwesomeIcon icon={faImage}/>
                            </a>}
                    </div>

                    <div className={date}>
                        {node.date}
                    </div>
                </div>

                <div className={titleAndProject}>
                    <div className={title}>
                        {node.url || node.image ?
                            <a href={node.url || node.image.publicURL} target="_blank" rel="noopener noreferrer"
                               className="underline">
                                {getMentionLinkTitle(node)}
                            </a> : node.title}
                        {node.note && (<em className={note}>
                            {" "} ({node.note})
                        </em>)}
                        {showRelatedTimestamps(node)}
                    </div>

                    {displayProjects && showRelatedProjects(node)}

                </div>

            </div>

        </li>
    )
}


const MentionList = ({ mentions, displayProjects, hideFeatured }) => {
    return (
        <ul className={mentionsContainer}>
            {mentions.map(({ node }) => Mention({ node, displayProjects, hideFeatured }))}
        </ul>
    )
}
MentionList.propTypes = {
    mentions: PropTypes.array.isRequired,
    displayProjects: PropTypes.bool
}
export default MentionList

export const query = graphql`
    fragment MentionFragment on PressMentionsJson {
        logo
        publisher
        publisherExtra
        url
        type
        title
        note
        timestamps
        projects
        date
        interview
        featured
        logoImage {
            id
            base
            childImageSharp {
                gatsbyImageData(
                    width: 58
                    placeholder: BLURRED
                    formats: [AUTO, PNG, WEBP, AVIF]
                )
                largeGatsbyImageData:gatsbyImageData(
                    width: 256
                    placeholder: BLURRED
                    formats: [AUTO, PNG, WEBP, AVIF]
                )
            }
        }
        image {
            publicURL
        }
        relatedProjects {
            ...ProjectOverviewFragment
        }
    }
`
